import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
// import Seo from "../components/Seo"
import { connect } from 'react-redux';
import Helper from '@justnice/helper';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import clsx from 'clsx';
import useCommonStyles from '../assets/jss/commonStyles';
import { 
  Button, Paper, TextField, Snackbar
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import * as sessionActions from '../redux/actions/sessionActions';
import ReCAPTCHA from "react-google-recaptcha";
import Api from '../lib/api';

const contactFormInitialState = () => {
  return {
    name: {value: '', touched: false, error: false, message: ''},
    email: {value: '', touched: false, error: false, message: ''},
    contact_num: {value: '', touched: false, error: false, message: ''},
    subject: {value: '', touched: false, error: false, message: ''},
    content: {value: '', touched: false, error: false, message: ''},
    reCaptcha: {value: null, touched: false, error: false, message: ''}
  };
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ContactPage : React.FC = (props) => {
  // const locale = props.ui.get('lang');
  const {t} = useTranslation('contact');
  const classes = useCommonStyles();
  const [contactForm, setContactForm] = React.useState(contactFormInitialState());
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [formSubmitStatus, setFormSubmitStatus] = React.useState({show: false, error: false, message: ''});
  
  const formFields = [
    {field: 'name', required: true, regExp: "^([A-Za-z]+([ ]?[a-z]?['-]?[A-Za-z]+)*)$", type: 'string', label: 'form.field.name'},
    {field: 'email', required: true, regExp: "^\\w+@[a-zA-Z_]+?\\.[a-zA-Z]{2,3}$", type: 'string', label: 'form.field.email'},
    {field: 'contact_num', required: false, regExp: "", type: 'string', label: 'form.field.contact_num'},
    {field: 'subject', required: true, regExp: '', type: 'string', label: 'form.field.subject'},
    {field: 'content', required: true, regExp: '', type: 'string', label: 'form.field.content'},
    {field: 'reCaptcha', required: true, regExp: '', type: 'string', label: 'form.field.reCaptcha'}
  ];
  const recaptchaRef = React.useRef();

  React.useEffect(() => {
    const auth = Helper.getStateAsJSObj(props['session'], 'auth.data', null);
    const isAuth = Helper.isNotNullAndUndefined(auth) && Helper.stringHasValue(auth.jwt) && Helper.isNotNullAndUndefined(auth.user);
    var myProfile = Helper.getStateAsJSObj(props['session'], 'myProfile.data', null);

    const prefillForm = (profile) => {
      let name = profile?.name?.first_name;
      if(Helper.stringHasValue(profile?.name?.last_name)) {
        name += ' ' + profile?.name?.last_name;
      }

      if(!Helper.stringHasValue(name)) {
        name = '';
      }

      let email = profile?.user?.email;
      if(!Helper.stringHasValue(email)) {
        email = '';
      }

      let localForm = Helper.deepCopy(contactForm);
      localForm['name'].value = name;
      localForm['email'].value = email;
      setContactForm(localForm);

    };

    if(isAuth && !Helper.isNotNullAndUndefined(myProfile)) {
      props.dispatch(
        sessionActions.myProfileGet((success, result) => {
          if(success && Helper.isNotNullAndUndefined(result, ['data'])) {
            // console.log('My Profile Success: ', result);
            myProfile = result.data;
            prefillForm(myProfile);
          }
        })
      );
    }

    if(isAuth && Helper.isNotNullAndUndefined(myProfile)) {
      prefillForm(myProfile);
    }

  }, []);

  const onFieldChange = (field: string, value : any, touched=null, error=null, message=null) => {
    let localForm = Helper.deepCopy(contactForm);
    localForm[field].value = value;
    localForm[field].touched = true;

    if(Helper.isNotNullAndUndefined(touched))
      localForm[field].touched = touched;

    if(Helper.isNotNullAndUndefined(error))
      localForm[field].error = error;

    if(Helper.isNotNullAndUndefined(message))
      localForm[field].message = message;

    setContactForm(localForm);
  };

  const validateFormField = (field, value) => {
    let formField = formFields.find(item => item.field === field);
    let localForm = Helper.isNotNullAndUndefined(contactForm) ? Helper.deepCopy(contactForm) : {};
    
    if(Helper.isNotNullAndUndefined(formField)) {
      localForm[field].touched = true;
      localForm[field].error = false;
      
      // If required, check if have value
      if(formField.required && !Helper.stringHasValue(value)) {
        // No value, set error to true and set message
        localForm[field].error = true;
        localForm[field].message = t('error.message.fieldRequired').replace('{0}', t(formField.label));
      }

      // If field have value and regex is not empty, check the regex
      if(Helper.stringHasValue(formField.regExp) && Helper.stringHasValue(value)) {
        let regExp = RegExp(formField.regExp);
        if(!regExp.test(value)) {
          localForm[field].error = true;
          localForm[field].message = t('error.message.fieldInvalid').replace('{0}', t(formField.label));
        }
      }
      
      setContactForm(localForm);
    }
  }

  const validateForm = (fieldsToValidate = [], form = null) => {
    let fields = Helper.arrayHasItem(fieldsToValidate) ? formFields.filter(item => fieldsToValidate.includes(item.field)) : formFields;
    let localForm = Helper.isNotNullAndUndefined(form) ? form : Helper.deepCopy(contactForm);
    let formIsValid = true;

    fields.forEach(item => {
      localForm[item.field].error = false;
      localForm[item.field].message = '';

      // If required, check if have value
      if(item.required && !Helper.stringHasValue(localForm[item.field].value)) {
        // No value, set error to true and set message
        localForm[item.field].error = true;
        localForm[item.field].message = t('error.message.fieldRequired').replace('{0}', t(item.label));
        formIsValid = false;
      }
      
      // If field have value and regex is not empty, check the regex
      if(Helper.stringHasValue(item.regExp) && Helper.stringHasValue(localForm[item.field].value)) {
        let regExp = RegExp(item.regExp);
        if(!regExp.test(localForm[item.field].value)) {
          localForm[item.field].error = true;
          localForm[item.field].message = t('error.message.fieldInvalid').replace('{0}', t(item.label));
          formIsValid = false;
        }
      }
    });

    setContactForm(localForm);
    return formIsValid;
  }

  return(
    <Layout title={t('pageTitle')} authRequired={false}>
      {/* <Seo title={t('title')} /> */}
      
      <Paper variant="outlined" className={clsx(classes.form)} style={{marginTop: 0, marginBottom: 0}}>
        <div className={classes.formRow}>{t('form.prompt')}</div>
        <div className={classes.formRow}>
          <TextField id="name" label={t('form.field.name')} 
            style={{display: 'flex', flex: 1}}
            variant="outlined" 
            value={contactForm.name.value} 
            error={contactForm.name.error}
            helperText={contactForm.name.message}
            onChange={event => onFieldChange('name', event.target.value)}
            onBlur={(event) => validateFormField('name', event.target.value)}
          />
        </div>
        <div className={classes.formRow}>
          <div className={clsx(classes.formCol)} style={{flex: 1, minWidth: 200}} >
            <TextField id="email" label={t('form.field.email')} 
              style={{display: 'flex'}}
              variant="outlined" 
              value={contactForm.email.value} 
              error={contactForm.email.error}
              helperText={contactForm.email.message}
              onChange={event => onFieldChange('email', event.target.value)}
              onBlur={(event) => validateFormField('email', event.target.value)}
            />
          </div>
          <div className={clsx(classes.formCol)} style={{flex: 1, minWidth: 200}} >
            <TextField id="contact_num" label={t('form.field.contact_num')} 
              style={{display: 'flex'}}
              variant="outlined" 
              value={contactForm.contact_num.value} 
              error={contactForm.contact_num.error}
              helperText={contactForm.contact_num.message}
              onChange={event => onFieldChange('contact_num', event.target.value)}
              onBlur={(event) => validateFormField('contact_num', event.target.value)}
            />
          </div>
        </div>
        <div className={classes.formRow}>
          <TextField id="subject" label={t('form.field.subject')} 
            style={{display: 'flex', flex: 1}}
            variant="outlined" 
            value={contactForm.subject.value} 
            error={contactForm.subject.error}
            helperText={contactForm.subject.message}
            onChange={event => onFieldChange('subject', event.target.value)}
            onBlur={(event) => validateFormField('subject', event.target.value)}
          />
        </div>
        <div className={classes.formRow}>
          <TextField id="content" label={t('form.field.content')} 
            style={{display: 'flex', flex: 1}}
            variant="outlined" 
            multiline={true}
            minRows={5}
            maxRows={10}
            value={contactForm.content.value} 
            error={contactForm.content.error}
            helperText={contactForm.content.message}
            onChange={event => onFieldChange('content', event.target.value)}
            onBlur={(event) => validateFormField('content', event.target.value)}
          />
        </div>
        <div className={classes.formRow} style={{justifyContent: 'center'}}>
          <div className={classes.formCol} style={{justifyContent: 'center', alignItems: 'center'}}>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.RECAPTCHA_SITE_KEY}
              onChange={(value) => {
                // console.log('ReCAPTCHA: ', value);
                onFieldChange('reCaptcha', value);
              }}
            />
            {
              contactForm.reCaptcha.error &&
              <span className={classes.errorText}>{t('error.message.recaptcha')}</span>
            }
          </div>
        </div>
        <div className={classes.formRow} style={{justifyContent: 'center'}}>
         <Button
            onClick={() => {
              setContactForm(contactFormInitialState());
            }}
            variant='contained'
            style={{width: 120}}
            disabled={formSubmitting}
          >
            {t('button.clear')}
          </Button>
          <Button
            color="primary"
            onClick={async () => {
              try {
                if(validateForm()) {
                  setFormSubmitting(true);
                  
                  let result = await Api.feedbackFormNew({
                    name: contactForm.name.value,
                    email: contactForm.email.value,
                    contact_num: contactForm.contact_num.value,
                    subject: contactForm.subject.value,
                    content: contactForm.content.value,
                    token: contactForm.reCaptcha.value
                  });

                  // console.log('feedbackFormNew: ', result);

                  setFormSubmitting(false);
                  if(Helper.isNotNullAndUndefined(recaptchaRef, ['current'])) {
                    recaptchaRef.current.reset();
                    onFieldChange('reCaptcha', null, null, false, '');
                  }

                  if(result.statusText === "OK") {
                    // Submit successful, show success message and clear the existing form.
                    setContactForm(contactFormInitialState());
                    setFormSubmitStatus({show: true, error: false, message: t('message.submitSuccess')});
                  }
                  else {
                    // Display error
                    setFormSubmitStatus({show: true, error: true, message: t('error.message.formSubmitFailed')});
                  }
                  
                }
              } catch (error) {
                setFormSubmitting(false);
                
                setFormSubmitStatus({show: true, error: true, message: Helper.getErrorMsg(error)});
                if(Helper.isNotNullAndUndefined(recaptchaRef, ['current'])) {
                  recaptchaRef.current.reset();
                  onFieldChange('reCaptcha', null, null, false, '');
                }
              }
              
            }}
            variant='contained'
            style={{width: 120}}
            disabled={formSubmitting}
          >
            {t('button.submit')}
          </Button>
        </div>
        <div className={classes.formCol}>
          <h3 style={{textDecoration: 'underline'}}>Company Detail</h3>
          <span>Company Name: Justnice.Net</span>
          <span>Address: 133 New Bridge Road #09-06, Chinatown Point, Singapore 059413</span>
          <span>Contact No.: 6795 9251</span>
          <span>Email: justshoot.it@justnice.net</span>
        </div>
      </Paper>

      <Snackbar open={formSubmitStatus.show} autoHideDuration={8000} onClose={() => setFormSubmitStatus({show: false, error: false, message: ''})}>
        <Alert onClose={() => setFormSubmitStatus({show: false, error: false, message: ''})} severity={formSubmitStatus.error ? 'error' : 'success'}>
          {formSubmitStatus.message}
        </Alert>
      </Snackbar>
    </Layout>
  )
}

export default connect((state) => {
  return {
    ui: state['ui'],
    page: state['page'],
    session: state['session']
  };
})(ContactPage);

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;